// Cloudinary
import {Cloudinary, Transformation, Configuration} from "cloudinary-core";
import {App, Plugin} from "vue";

type PublicIdOrObjectWithIt = undefined | string | {
    public_id: string;
    [key: string]: unknown;
}

export interface CloudinaryHelper {
    /* You can pass public id as string or object that contains public_id prop */
    url(image: PublicIdOrObjectWithIt, params?: Transformation.Options): string,
    /* You can pass public id as string or object that contains public_id prop */
    bgUrl(image: PublicIdOrObjectWithIt, options?: Transformation.Options): string,
}

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $cld: CloudinaryHelper
    }
}

const CloudinaryPlugin: Plugin = {
    install(app: App, options: Configuration.Options) {
        const cloudinaryConfig: Configuration.Options = {
            cloud_name: "songfinch",
            secure: true,
            quality: "auto",
            cname: "media.songfinch.com",
            private_cdn: true,
            secure_distribution: "media.songfinch.com",
            ...options
        };

        const cldObject = new Cloudinary(cloudinaryConfig);

        app.config.globalProperties.$cld = {
            url(image, params?) {
                if (!image) return "";
                params = {
                    quality: "auto",
                    fetch_format: "auto",
                    ...params
                };
                if (typeof image === "object") {
                    return cldObject.url(image.public_id, params);
                } else {
                    return cldObject.url(image, params);
                }
            },
            bgUrl(image, params?) {
                return image ? `url(${this.url(image, params)})` : "";
            }
        };

        app.provide("$cld", app.config.globalProperties.$cld);
    }
};

export default CloudinaryPlugin;
